import React,{useEffect, useState} from "react";
import { saveAs } from 'file-saver';
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Button } from "react-bootstrap";
import $ from "jquery";
import "./StickyButton.scss"

// markup
const OffPlanStickyButton = (props) => {
  let property = props.data;
  const downloadBrochure = (url) => {
    saveAs(url, "Brochure.pdf");
  }

  useEffect(() => {
    // New home click to scroll
    $(".details_features").click(function () {
      $('html, body').animate({
        scrollTop: $("#register").offset().top - 100
      }, 1000);
    }); 
  }, []);

  return (

    <React.Fragment>
      <div className="sticky-btn d-lg-none off-plan-sticky">
        {property.Upload_Brochure ?
          <>
            <button className="btn btn-primary details_features">
              Register Your Interest
            </button>

            <a href="#" className="btn btn-secondary" onClick={() => { downloadBrochure(`${property.Upload_Brochure.url}`) }}>  Brochure </a>
          </>
          : <button className="btn btn-primary details_features full-width">
            Register Your Interest
          </button>
        }
      </div>
    </React.Fragment>
  )
}

export default OffPlanStickyButton
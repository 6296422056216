import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"

import "./Payment.scss"
// markup
const PaymentPlan = (props) => {
    let payments = props.data;
    return (
        <React.Fragment>
            <section className="payment" id="payment">
                <div animateIn='fadeInUp' animateOnce>
                    <Container>
                        <Row>   
                            <Col><h3>Payment Plan</h3></Col>                         
                        
                        </Row>
                        <Row>
                        {payments.map((payment, i) => {
                               return (
                            <div class="col-12 col-md-6">   
                                <div className="plan-info">
                                    <div className="payment-plan payment-plan-grid"> 
                                         
                                                <div className="plan-details">
                                                    <span className="text-lg-bold">{payment.Title}</span>
                                                    <span className="percent">{payment.Value}</span>
                                                    <span className="text-md-bold">{payment.Description}</span>
                                                </div>
                                          

                                    </div>
                                </div>
                            </div>
                              )
                            })}
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default PaymentPlan